export default {
  en: {
    section_1: {
      title: 'Company',
      links: [
        { href: '/about-us', hrefTitle: 'Our Story'},
        { href: '/about-us#the-team', hrefTitle: 'Leadership'},
        { href: '/careers', hrefTitle: 'Careers'},
        // { href: 'mailto:hello@trebelmusic.com?subject=Website%20Contact', type:'html', hrefTitle: 'Contact'},
      ]
    },
    section_2: {
      title: 'Partners',
      links: [
        { href: '/partners', hrefTitle: 'Advertisers'},
        { href: '/partners#content', hrefTitle: 'Content Providers'},
      ]
    },
    section_3: {
      title: 'Legal',
      links: [
        { href: '/terms-of-service', hrefTitle: 'Terms'},
        { href: '/privacy-policy', hrefTitle: 'Privacy'},
        { href: '/copyright', hrefTitle: 'Claims'},
      ]
    }
  },
  es: {
    section_1: {
      title: 'EMPRESA',
      links: [
        { href: '/es/sobre-nosotros', hrefTitle: 'Nuestra Historia'},
        { href: '/es/sobre-nosotros#the-team', hrefTitle: 'Equipo'},
        { href: '/es/empleos', hrefTitle: 'Empleos'},
        { href: 'mailto:hola@trebelmusic.com?subject=Website%20Contact', hrefTitle: 'Contacto'},
      ]
    },
    section_2: {
      title: 'Business',
      links: [
        { href: '/es/business#content', hrefTitle: 'Contenido'},
        { href: '/es/business', hrefTitle: 'Publicidad'},
      ]
    },
    section_3: {
      title: 'Recursos',
      links: [
        { href: '/es/terminos', hrefTitle: 'Términos'},
        { href: '/es/politica-de-privacidad', hrefTitle: 'Privacidad'},
        { href: '/es/copyright', hrefTitle: 'Reclamaciones'},
        { href: 'https://support.trebel.io/', hrefTitle: 'Centro De Ayuda'},
      ]
    }
  },
  id: {
    section_1: {
      title: 'Hukum',
      links: [
        { href: '/id/terms-of-service', hrefTitle: 'Ketentuaan'},
        { href: '/id/privacy-policy', hrefTitle: 'Privaci'},
        { href: '/id/copyright', hrefTitle: 'Klaim laporan'},
      ]
    },
    section_2: {
      style: 'grid-area: span 1 / span 2 / span 1 / span 2;',
      title: 'PERUSAHAAN',
      subtitle: 'Layanan Pengaduan Konsumen',
      links: [
        {
          href: 'mailto:support.id@trebel.io?subject=Dukungan%20Pelanggan%20%7C%20Indonesia',
          hrefTitle: 'support.id@trebel.io',
          class: 'email-link',
        },
        {
          type: 'html',
          href: 'mailto:support.id@trebel.io?subject=Dukungan%20Pelanggan%20%7C%20Indonesia',
          hrefTitle: "PT. Trebel Music and Media Indonesia<br>MNC Studio Tower 2, jl. perjuangan No. 1,RT.5/RW.10, kb. jeruk, Kec. Kb. Jeruk, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11530 <br>‍<br>+62 21 5303 655<br>‍<br>direktorat Jenderal Perlindungan Konsumen Dan Tertib Niaga Kementerian Perdagangan Republik Indonesia<br> <br>0853 1111 1010 (WhatsApp)<br><br>‍</a> "
        },
      ]
    }
  },
  br: {
    section_1: {
      title: 'JURÍDICO',
      links: [
        { href: '/br/terms', hrefTitle: 'Termos'},
        { href: '/br/privacy', hrefTitle: 'Privacidade'},
      ]
    },
  }
}
